<template>
  <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
    <template v-slot:error-message>
      <span class="pl-2 text-md">{{ errorMessage }}</span>
    </template>
  </error-toast>
  <nav class="flex justify-between items-center mr-32">
    <BackArrow :pageTitles="{ heading: `${ $route.params.year } Budget`, content: 'back to budgets' }" />
  </nav>
  <main class="mx-32">
    <section class="flex items-start overflow-auto">
      <div @click="getBudgetByDepartment(data.department)" v-for="data in departments" :key="data.id" :data-department="data.department" class="budget-department-card w-1/5 mr-3 py-10 px-10 border border-borderColor rounded capitalize text-center text-xl">{{ data.department }}</div>
    </section>
    <section v-if="requestCompleted && budget" class="w-full flex justify-between items-start bg-white my-10 p-6 rounded shadow text-sm">
      <BudgetDataTable :budget="budget" class="w-full mr-10" />
      <div class="w-1/5 border border-borderColor rounded tracking-widest text-xs leading-4">
        <div>
        </div>
        <h1 class="uppercase p-4">{{ $route.params.year }} budget</h1>
        <hr>
        <div class="p-4">
          <h1 class="uppercase">amount</h1>
          <div class="text-2xl tracking-tighter py-2 font-bold">N150,000,000</div>
        </div>
        <div class="p-4 pb-8">
          <h1 class="uppercase">utilization</h1>
          <div class="text-5xl py-2 font-extrabold">80%</div>
        </div>
      </div>
    </section>
    <LoadingSpinner v-if="loading" />
    <div v-if="requestCompleted && !loading && !budget" class="text-lg font-semibold text-center mt-20">No data found for this department</div>
    <div v-if="!requestCompleted && !budget" class="text-lg font-semibold text-center mt-20">Select a department above to view data</div>
  </main>
</template>

<script>
import Request from '@/services/requestHelper'
import BackArrow from '@/components/ui/BackArrow.vue'
import BudgetDataTable from '@/components/BudgetDataTable.vue'
import ErrorToast from '@/components/notificationToasts/ErrorToast.vue'

export default {
  name: 'BudgetHome',
  props: { year: String },

  async mounted() {
    this.requestCompleted = false
    this.getAllDepartments()
  },
  updated() {
    this.$nextTick(() => {
      const elements = document.querySelectorAll('.budget-department-card')
      elements[elements.length - 1].style.marginRight = '0'
    })
  },
  methods: {
    selectDomElement() {
      try {
        const elements = document.querySelectorAll('.budget-department-card')
        elements.forEach((element) => {
          element.classList.remove('card-background')

          if (element.dataset.department === this.budgetDepartment) {
            element.classList.add('card-background')
          }
        })
      } catch (error) {
        this.errorMessage = error.response.data.message
      }
    },
    async getAllDepartments() {
      try {
          const { status, data } = await Request.getRequest('departments/all')

          if (status >= 200 && status < 300) {
            this.departments = data.data
          }
      } catch (error) {
          this.errorMessage = error.response.data.message
      }
    },
    async getBudgetByDepartment(department) {
      try {
          this.loading = true
          this.budget = null
          const { status, data } = await Request.getRequest(`budget/department/${department}/year/${this.year}`)

          if (status >= 200 && status < 300) {
            this.requestCompleted = true
            this.loading = false
            this.budgetDepartment = data.data.department
            this.budget = data.data
            this.selectDomElement()
          }
      } catch (error) {
        this.requestCompleted = true
        this.loading = false
        this.errorMessage = error.response.data.message
      }
    }
  },
  data() {
    return {
      isOperationsActive: true,
      isNetworkActive: false,
      isRetailActive: false,
      isBusinessActive: false,
      budget: null,
      budgetDepartment: null,
      departments: null,
      errorMessage: null,
      requestCompleted: false,
      emptyBudget: true,
      hasDepartment: false,
      loading: false

    }
  },
  components: { BackArrow, BudgetDataTable, ErrorToast }
}
</script>

<style scoped>
.card-background {
  background-color: var(--primary-color);
  color: #fff;
}
</style>
